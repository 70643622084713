
import "unpoly";

type Unpoly = {
  submit: (form: HTMLFormElement, options: { target: string }) => void;
}

declare global {
  var up: Unpoly;
}

type FinixForm = {
  submit: (environment: string, applicationId: string, callback: (err: any, res: any) => void) => void;
};

export default class Finix {
  debug(...args: any[]) {
    console.log("#finix", ...args);
  }

  onSubmit(event: SubmitEvent, finix_form: FinixForm, tokenInput: HTMLInputElement, form: HTMLFormElement, application_id: string) {
    form.classList.add("up-active");
    event.preventDefault();
    event.stopPropagation();

    this.debug('before finix_form.submit');
    finix_form.submit('sandbox', application_id, (err, res) => {  // call the form submit function and supply the environment and application ID to submit under
      this.debug('res',res,'err',err);
      form.classList.remove("up-active")
      if (res) {
        const tokenData = res.data || {};
        const token = tokenData.id;
        tokenInput.value = token;
        up.submit(form, { target: '#body' });       
      } else {
        tokenInput.value = '';
      }
    });
  }
}
