import Toastify from "toastify-js";

up.compiler("#paypalButton", function(el, paypal_data) {
  paypal.Buttons({
    style: {
      layout:  'vertical',
      color:   'gold',
      shape:   'pill',
      label:   'pay',
      tagline: false,
    },
    createOrder: function(data, actions) {
      return actions.order.create(paypal_data);
    },
    onApprove: function(data) {
      up.emit("up:proxy:slow");
      up.replace("#body", el.dataset.confirmUrl, {
        method: "post",
        params: {
          paypal_order_id: data.orderID
        }
      })
    },
    onError: function(error) {
      Toastify({
        text: "Payment failed. Please contact customer support.",
        duration: 3000,
        gravity: "top",
        positionLeft: false,
        backgroundColor: `var(--red-500)`,
        stopOnFocus: false
      }).showToast();
      up.emit("up:proxy:recover");
    }
  }).render(el);
})