import Alpine from "alpinejs"
import "shared/sentry"
import "shared/unpoly"
import "shared/autosize"
import "shared/bluesnap"
import "shared/mollie"
import "shared/captcha"
import "shared/flash"
import "shared/paypal"
import "shared/pin"
import "shared/poll"
import "shared/image_zoom"
import "shared/redirect"
import "shared/target_value"
import "shared/active_storage"
import "shared/uppy"
import "shared/clipboard"
import "shared/google_analytics"
import "shared/intercom"
import "shared/rails"
import "suite/spiders"
import "flag-icons/css/flag-icons.css"
import "suite/styles/main.css"
import Finix from "suite/payment/finix"

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import countdown_timer from 'suite/countdown_timer'

import "suite/fb_track_nc"
import "suite/gtm_data_layer_push"
import WheelOfFortune from "suite/wheel_of_fortune"
import Cookies from "js-cookie"

import * as suite_ns from "suite/suite_ns"

window.Alpine = Alpine;
document.addEventListener("DOMContentLoaded", function(event) {
  Alpine.start()
});

window.WheelOfFortune = WheelOfFortune
window.Cookies = Cookies
window.countdown_timer = countdown_timer

window.PaymentProviders = {
  finix: Finix
}

window.suite_ns = suite_ns;