import Toastify from "toastify-js";

up.compiler("[cc-data]", function(field) {
  const token = up.element.get("[cc-token]")
  const threeDSecureField = up.element.get("[cc-3ds]")
  const threeDSecureInfo = up.element.get("#three_d_secure_info")
  const form = up.element.closest(field, "form")
  const errorHandler = form.matches('[data-bluesnap-error-handler]') ? form : form.querySelector('[data-bluesnap-error-handler]')
  const data = JSON.parse(field.value)

  let errors = {};
  let submitErrorHandler = undefined;

  function debug(...args) {
    // console.log("#BlueSnap", ...args);
  }

  const $dispatch = (name, payload) => {
    var evt = new CustomEvent(name, {detail: {...payload}});
    errorHandler?.dispatchEvent(evt); 
  }

  debug("bs.error.handler", errorHandler);

  var threeDSecureObj = threeDSecureInfo ? JSON.parse(threeDSecureInfo.dataset.threeDSecure) : undefined;
  bluesnap.hostedPaymentFieldsCreate({
    token: token.value,
    onFieldEventHandler: {
      onFocus: function (tagId) {
        debug("onFocus", tagId);
      },
      onBlur: function (tagId) {
        debug("onBlur", tagId);
      },
      onError: function (tagId, errorCode ,errorDescription, eventOrigin, updateAccessibilityError) {
        debug("onError", tagId, errorCode, errorDescription, eventOrigin, updateAccessibilityError);
        if (submitErrorHandler != undefined) {
          submitErrorHandler(tagId, errorCode, errorDescription);
        }
        errors[tagId] = {code: errorCode, description: errorDescription};
        $dispatch('bluesnap-errors', errors);
        Sentry.captureMessage(`BlueSnap.onError - tagId:${tagId} errorCode:${errorCode} errorDescription:${errorDescription}`, {tagId, errorCode, errorDescription});
      },
      onEnter: function (tagId) {
        debug("onEnter", tagId);
      },
      threeDsChallengeExecuted: function () {
        debug("threeDsChallengeExecuted");
      },
      setupComplete: function() {
        debug("setupComplete");
      },
      onType: function (tagId, cardType  /*, cardData*/) {
        debug("onType", tagId, cardType);
      },
      onValid: function (tagId) {
        if (errors[tagId]) {
          delete errors[tagId];
          $dispatch('bluesnap-errors', errors);
        }
        debug("onValid", tagId);
      },
    },
    style: {
      "input": {
        "font-size": "16px"
      }
    },
    ccnPlaceHolder: data && data.last_four_digits ? `•••• •••• •••• ${data.last_four_digits}` : "Card number",
    cvvPlaceHolder: data && data.last_four_digits ? "•••" : "CVV",
    expPlaceHolder: data && data.last_four_digits ? "•• / ••" : "MM / YY",
    "3DS": threeDSecureInfo ? true : false
  },  threeDSecureObj);
  form.addEventListener("submit", function(e) {
    debug("cc-data.submit", e);
    e.preventDefault();
    e.stopPropagation();
    form.classList.add("up-active");
    submitErrorHandler = (tagId, errorCode, description, eventOrigin, updateAccessibilityError) => {
      debug("submitErrorHandler", tagId, errorCode, description, eventOrigin, updateAccessibilityError);
      console.log("error during submit:", errorCode, description);
      form.classList.remove("up-active");
      submitErrorHandler = undefined;
    }

    bluesnap.hostedPaymentFieldsSubmitData(function(callback) {
      debug("hostedPaymentFieldsSubmitData callback",callback.cardData, "3ds", callback.threeDSecure);
      if ((null != callback.threeDSecure) && (threeDSecureField != null)) {
        threeDSecureField.value = JSON.stringify(callback.threeDSecure);
      }
      if (null != callback.cardData) {
        field.value = JSON.stringify(callback.cardData);
        up.submit(form, { target: '#body' })
      } else {
        debug("error", callback.error)
        if (callback.error[0].errorDescription === "\"EXPIRED_TOKEN\"") {
          let url = new up.Params({card_token_expired: true}).toURL(window.location.href);
          up.reload(form, {url});
        }
        Toastify({
          text: callback.error[0].errorDescription,
          duration: 3000,
          gravity: "top",
          positionLeft: false,
          backgroundColor: `var(--red-500)`,
          stopOnFocus: false
        }).showToast();
        form.classList.remove("up-active")
      }
    }, threeDSecureObj);
    return false;
  })
});