import "unpoly";

type Unpoly = {
  submit: (form: HTMLFormElement, options: { target: string }) => void;
}


type FinixForm = {
  submit: (environment: string, applicationId: string, callback: (err: any, res: any) => void) => void;
};

type FinixBinInformation = {
  bin: string;
  cardBrand: string;
};

type FinixFieldState = {isFocused: boolean, isDirty: boolean, errorMessages: string[]};

type FinixState = {
  name: FinixFieldState;
  number: FinixFieldState;
  security_code: FinixFieldState;
  expiration_date: FinixFieldState;
  "address.country": FinixFieldState;
};

type FinixGlobal = {
  TokenForm: (formId: string, options: { onUpdate: (state: FinixState, binInformation: FinixBinInformation|undefined, formHasErrors: boolean) => void }) => FinixForm;
  CardTokenForm: (formId: string, options: { onUpdate: (state: FinixState, binInformation: FinixBinInformation|undefined, formHasErrors: boolean) => void }) => FinixForm; 
  Auth: (environment: string, merchantId: string, callback: (sessionKey: string) => void) => void;
};

declare global {
  var up: Unpoly;
  var Finix: FinixGlobal;
}


type BinCallback = (binInformation: FinixBinInformation) => void;
type HasErrorCallback = (formHasErrors: boolean) => void;

export class Form {
  finixForm: FinixForm;
  form: HTMLFormElement
  applicationId: string;
  merchantId: string;
  environment: string;
  lastBinCallback?: BinCallback
  hasErrorCallback?: HasErrorCallback
  fields: { token?: HTMLInputElement, session?: HTMLInputElement  } = {}

  constructor(form: HTMLFormElement, environment: string, applicationId: string, merchantId: string) {
    this.form = form
    this.applicationId = applicationId
    this.merchantId = merchantId
    this.environment = environment

    const form_id = form.id
    this.finixForm = window.Finix.CardTokenForm(form_id, {
      onUpdate: (state, binInformation, formHasErrors) => {
        console.log('#finix onUpdate', state, binInformation, formHasErrors);
        if (binInformation && binInformation && binInformation.bin != '' && binInformation.cardBrand != '') {
          console.log(" => binInformation:", binInformation)
          this.lastBinCallback?.(binInformation)
        }
        console.log(" => formHasErrors:", formHasErrors) 
        this.hasErrorCallback?.(formHasErrors)
      }
    })

    window.Finix.Auth(environment, merchantId, (sessionKey) => {
      if (this.fields.session) {
        this.fields.session.value = sessionKey;
      }
    })
  }

  onLastBin(callback: BinCallback) {
    this.lastBinCallback = callback;
  }

  onHasError(callback: HasErrorCallback) {
    this.hasErrorCallback = callback;
  }

  setFields(fields: { token: HTMLInputElement, session: HTMLInputElement }) {
    console.log(" ===> fields", fields);
    this.fields = { ...this.fields, ...fields }
  }

  debug(...args: any[]) {
    console.log("#finix", ...args);
  }

  submit(event: SubmitEvent) {
    this.form.classList.add("up-active");
    event.preventDefault();
    event.stopPropagation();

    this.debug('before finix_form.submit');
    console.log("=> Submit", event); 

    this.finixForm.submit(this.environment, this.applicationId, (err, res) => { 
      this.debug('res',res,'err',err);
      this.form.classList.remove("up-active")
      if (res) {
        const tokenData = res.data || {};
        const token = tokenData.id;
        if (this.fields.token) {
          this.fields.token.value = token;
        }
        up.submit(this.form, { target: '#body' });
      } else {
        if (this.fields.token) {
          this.fields.token.value = '';
        }
      }
    });
  }
}