import './bug';

const key = 'spiderCount2022';

function showSpider(element) {
  const spiderController = new window.SpiderController({
    imageSprite: element.dataset.imageSprite,
    minBugs: 1,
    maxBugs: 1,
    bugWidth: 140,
    bugHeight: 104,
    num_frames: 12,
    allowStationary: false,
    mouseOver: 'nothing',
    zoom: 10,
  });

  up.on('click', 'div.bug', () => {
    window.localStorage.setItem(key, 20);
    window.open(element.dataset.urlToOpen);
    spiderController.end();
  });
}

up.compiler("[campaign-spiders]", element => {
  const spiderCount = parseInt(window.localStorage.getItem(key), 10) || 0;
  if (spiderCount < 10) {
    window.localStorage.setItem('spiderCount2022', spiderCount + 1);
    showSpider(element);
  }
})