up.compiler("[data-target-value]", (el) => {
  const output = up.element.get(el.dataset.targetValue);
  output.value = el.value;
  output.oninput = () => {
    el.value = output.value;
  }
  el.oninput = () => {
    output.value = el.value;
  }
})
