const renderCaptcha = (el) => {
  if (typeof grecaptcha !== "undefined" && grecaptcha.ready) {
    grecaptcha.render && grecaptcha.render(el, {
      sitekey: CAPTCHA_SITEKEY,
      callback: () => {
        up.emit(el.closest("form"), "submit")
      }
    })
  }
}
window.renderCaptchas = () => {
  up.element.all("[data-captcha]").forEach(el => renderCaptcha(el))
}
up.compiler("[data-captcha]", function(el) {
  renderCaptcha(el)
})