up.compiler("[data-pin]", element => {
  let timeout;

  element.addEventListener("keypress", (e) => {
    const { keyCode, metaKey } = e;
    
    if (metaKey || (keyCode >= 48 && keyCode <= 57)) {
      // The delay is necessary when checking a pasted value
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (e.target.value.length === 6) {
          const form = up.element.closest(element, "form");
          up.submit(form);
        }
      }, 100)
    } else {
      e.preventDefault()
    }
  })

  return () => {
    element.removeEventListener("keypress")
  }
})

