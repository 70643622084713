
up.compiler("[data-mollie-checkout]", function(field) {
  function debug(...args) {
    console.log("#Mollie", ...args);
  }

  debug("mollie checkout loaded");
  debug("initlizalzing mollie")
  if (window.MollieInstance == null) {
    window.MollieInstance = window.Mollie(field.dataset.mollieProfileId, { locale: 'en_GB', testmode: true });
  }
  var mollie = window.MollieInstance;

  const form = up.element.closest(field, "form")
  const errorHandler = form.matches('[data-bluesnap-error-handler]') ? form : form.querySelector('[data-bluesnap-error-handler]')
  const $dispatch = (name, payload) => {
    var evt = new CustomEvent(name, {detail: {...payload}});
    errorHandler?.dispatchEvent(evt); 
  }

  var options = {
    styles : {
      base: {
        color: '#999',
        fontSize: '14px',
        '::placeholder' : {
          color: 'rgba(68, 68, 68, 0.2)',
        }
      },
      valid: {
        color: '#090',
      }
    }
  };

  if (mollie.alreadyMounted == null) {
    console.log("adding fields");
    let cardNumber = mollie.createComponent('cardNumber', options);
    cardNumber.mount('[data-mollie~=ccn]');
    console.log("adding fields");
    let expiryDate = mollie.createComponent('expiryDate', options);
    expiryDate.mount('[data-mollie~=exp]');
    console.log("adding fields");
    let verificationCode = mollie.createComponent('verificationCode', options);
    verificationCode.mount('[data-mollie~=ccv]');

    let cardHolder = mollie.createComponent('cardHolder', options);
    cardHolder.mount('[data-mollie~=cch]');

    [cardNumber,expiryDate,expiryDate,cardHolder].forEach(field => {
      field.addEventListener('change', function (event) {
        if (event.error && event.touched) {
          $dispatch('mollie-errors', {error: event.error});
        } else {
          $dispatch('mollie-errors', {error: null});
        }
      });
    })
    mollie.alreadyMounted = true;
  } else {
    debug("already mounted => skipping mollie init");
  }

  form.addEventListener('submit', async e => {
    e.preventDefault();
    e.stopPropagation();
  
    var { token, error } = await mollie.createToken();
    form.classList.add("up-active");

    if (error) {
      console.error("Error from mollie: ", error);
      // Something wrong happened while creating the token. Handle this situation gracefully.
      return;
    }

    // Add token to the form
    const tokenInput = up.element.get("[mollie-cc-token]")

    //var tokenInput = document.createElement('input');
    //tokenInput.setAttribute('type', 'hidden');
    //tokenInput.setAttribute('name', 'cardToken');
    //tokenInput.setAttribute('value', token);
    tokenInput.setAttribute('value', token);
    debug("SET MollieToken", token, tokenInput.value)
    

    form.appendChild(tokenInput);

    form.submit()
  })
});