interface CountdownTimer {
  deadline: string;
  remaining_ms: string|null;
  timer?: NodeJS.Timeout;
  init(): void;
};

export default function countdown_timer(deadline: string) : CountdownTimer {

  function get(): string|null { 
    let diff = (Date.parse(deadline) - Date.now()) / 1000;
    if (diff > 0) {
      let minutes = Math.floor(diff / 60);
      let seconds = Math.floor(diff % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      return `${minutes}:${seconds}`;
    } else {
      return null;
    }
  }

  return {
    deadline: deadline,
    remaining_ms: get(),
    init() {
      let me = this;
      this.timer = setInterval(() => {
        me.remaining_ms = get();
      }, 1000);
    }
  }
}
